import React, { Component } from "react";
import styles from "./FastFlowAckSlider.module.scss";
import addArtistStyles from "./LinkAnotherArtist.module.scss";
import { ReactComponent as HoldTightLoader } from "../../../../assets/logos/beatBread-circle-pulse.svg";
import { ReactComponent as CCHoldTightLoader } from "../../../../assets/logos/cc-vector.svg";
import { ReactComponent as GraphSvg } from "../../../../assets/logos/graph.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/logos/Alert.svg";
import { ReactComponent as CheckMarkSpike } from "../../../../assets/logos/checkmark-spiked.svg";

import {
  A_BIT_LONGER,
  ESTIMATES_FIELD_NAMES,
  FAST_FLOW_STEPS,
  KNOWLEDGE_CENTER,
  MAX_POLLING_TIME,
  CHOOSE_PATH_FIELDS,
} from "./constant";
import { numberWithCommas } from "../../YourAdvance/helper";
import FormikForm from "../../../../component/Form/FormikForm";
import { Form, Formik } from "formik";
import FormField from "../../../../component/FormField/FormField";
import * as Yup from "yup";
import request from "../../../../utils/request";
import {
  API_URL,
  ARTIST_API,
  AUTH,
  VERIFY_STREAMING_INCOME,
} from "../../constants";
import Loader from "../../../../component/Loader";
import { get, invoke } from "lodash";
import { toast } from "react-toastify";
import {
  getPartnerName,
  getSubDomain,
} from "../../../../component/ThemeManager/helper";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import segment from "../../../../utils/segment";
import {
  POR_DEFAULT_OPTION,
  PUBLISHING_FIELDS,
  RIGHTS_TYPES,
} from "../../YourAdvance/constants";

export class HoldTightWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBitLonger: false,
      showAlmostDone: false,
      showHoldTexts: true,
    };
  }

  componentDidMount() {
    segment.track.offersPending();
    this.showBitLongerTimeOut = setTimeout(() => {
      this.setState(
        { showBitLonger: true, showHoldTexts: false },
        this.renderBitLonger,
      );
      clearInterval(this.blueTextInterval);
    }, MAX_POLLING_TIME);
  }

  componentWillUnmount() {
    clearInterval(this.blueTextInterval);
    clearTimeout(this.showBitLongerTimeOut);
    clearTimeout(this.bitLongerTimeOut);
    clearTimeout(this.apiFailTimeOut);
  }

  renderBitLonger = () => {
    this.bitLongerTimeOut = setTimeout(() => {
      this.setState(
        { showBitLonger: false, showAlmostDone: true },
        this.callApiFail,
      );
    }, A_BIT_LONGER);
  };

  callApiFail = () => {
    this.apiFailTimeOut = setTimeout(() => {
      this.props.calledApiFail();
    }, A_BIT_LONGER);
  };

  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={`${styles.content} ${styles.holdTightWidget}`}>
          {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 ? (
            <div className={`${styles.ccIcon}`}>
              <CCHoldTightLoader />
            </div>
          ) : (
            <div className={`${styles.icon}`}>
              <HoldTightLoader />
            </div>
          )}
          <>
            <div className={styles.text}>
              <div className={styles.titleDark}>Hold tight</div>
            </div>
            {this.state.showHoldTexts && (
              <div
                className={`${styles.subtitle} ${styles.countDownList} ${
                  this.state.showBitLonger && styles.fadeOut
                }`}
              >
                <div className={styles.blueText}>
                  Gathering information and generating estimates...
                </div>
              </div>
            )}

            {this.state.showBitLonger && (
              <div
                className={`${styles.blueText} ${
                  this.state.showBitLonger && styles.fadeIn
                }`}
              >
                Just a little bit longer...
              </div>
            )}

            {this.state.showAlmostDone && (
              <div
                className={`${styles.blueText} ${
                  this.state.showAlmostDone && styles.fadeIn
                }`}
              >
                Sorry, almost done, less than a minute remaining...
              </div>
            )}
          </>
        </div>
      </div>
    );
  }
}

export class LongerThanExpected extends Component {
  componentDidMount() {
    segment.track.quoteDelayed();
  }
  render() {
    const {
      title,
      subtitle,
      linkText,
      firstBtnText,
      secondBtnText,
      isFinalExpected,
      uploadReports,
    } = this.props;
    return (
      <div className={styles.widgetBox}>
        <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
          <div className={styles.icon}>
            <AlertIcon />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={`${styles.subtitle} ${styles.ltesubtitle}`}>
            {subtitle}
          </div>
          <div className={styles.footerText}>{linkText}</div>
          {isFinalExpected ? (
            <div className={styles.btnContainer}>
              <a
                className={styles.secondaryBtn}
                href={`${process.env.REACT_APP_HOMEPAGE_URL}${KNOWLEDGE_CENTER}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {firstBtnText}
              </a>
              <button className={styles.primaryBtn} onClick={uploadReports}>
                {secondBtnText}
              </button>
            </div>
          ) : (
            <div className={styles.btnContainer}>
              <button className={styles.primaryBtn} onClick={uploadReports}>
                {firstBtnText}
              </button>
              <button
                className={styles.secondaryBtn}
                onClick={this.props.onClick}
              >
                {secondBtnText}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export class IncomeNotVerified extends Component {
  componentDidMount() {
    segment.track.incomeOverreported();
  }
  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
          <div className={styles.icon}>
            <AlertIcon />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>Income Not Verified</div>
          </div>
          <div className={`${styles.subtitle}`}>
            We couldn’t verify the income you entered - but don’t worry, we can
            try another way.
          </div>
          <div className={`${styles.subtitle} ${styles.uploadDiv}`}>
            Upload your distributor reports now to verify your data.
          </div>
          <div className={styles.btnContainer}>
            <button
              className={styles.primaryBtn}
              onClick={this.props.uploadReports}
            >
              UPLOAD REPORTS
            </button>
            <a
              className={styles.secondaryBtn}
              href={`${process.env.REACT_APP_HOMEPAGE_URL}${KNOWLEDGE_CENTER}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export class FinalHoldTightWidget extends Component {
  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={`${styles.content} ${styles.holdTightWidget}`}>
          {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 ? (
            <div className={`${styles.ccIcon}`}>
              <CCHoldTightLoader />
            </div>
          ) : (
            <div className={`${styles.icon}`}>
              <HoldTightLoader />
            </div>
          )}
          <div className={styles.text}>
            <div className={styles.titleDark}>Just a bit longer...</div>
          </div>
        </div>
      </div>
    );
  }
}

export class IncomeVerified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      amount: 0,
      monthlyIncome: this.props.monthlyIncome,
      showstreamingInput: this.props.showstreamingInput,
    };
  }

  isPulishingOffering = () => {
    return (
      get(this.props, "isPublishing") &&
      this.props.rightsType === RIGHTS_TYPES[0].value &&
      this.props.PRO !== POR_DEFAULT_OPTION.value
    );
  };

  validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .integer()
      .min(1)
      .label(this.isPulishingOffering() ? "This" : "Streaming Income"),
  });

  componentDidMount() {
    segment.track.incomeWarning();
  }

  getInputLabel = () => {
    return this.isPulishingOffering()
      ? PUBLISHING_FIELDS.INCOME.LABEL
      : ESTIMATES_FIELD_NAMES.AMOUNT.LABEL;
  };

  getIncomeValue = () => {
    return this.props.rightsType === RIGHTS_TYPES[0].value
      ? this.props.recentRevenue
      : this.state.monthlyIncome;
  };

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      streamingIncome: values.amount,
      artistId: this.props.artistId,
      requestId: this.props.requestId,
    };
    const requestURL = `${API_URL}${AUTH}${ARTIST_API}${VERIFY_STREAMING_INCOME}`;
    const data = {
      method: "POST",
      body: payload,
    };
    request(requestURL, data)
      .then((res) => {
        if (
          get(res, "data.streamingIncomeCounter", 0) < 3 &&
          get(res, "data.artistStatus") === FAST_FLOW_STEPS.INCOME_VERIFIED
        ) {
          this.setState({
            showstreamingInput: false,
            monthlyIncome: values.amount,
            loading: false,
          });
          return true;
        }
        this.setState({ loading: false });
        return this.props.handleStreamingIncome(res);
      })
      .catch((err) => {
        toast.error(err?.message);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div className={styles.widgetBox}>
        {!this.state.showstreamingInput ? (
          <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
            <div className={styles.icon}>
              <AlertIcon />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Verify Monthly Income</div>
            </div>
            <div className={`${styles.subtitle} ${styles.reportedIncome}`}>
              Reported Income:{" "}
              <span>${numberWithCommas(this.getIncomeValue())}</span>
            </div>
            <div className={styles.subtitle}>
              Our data suggests that the income reported is too high. Is this
              income correct?
            </div>
            <div className={styles.btnContainer}>
              <button
                className={styles.primaryBtn}
                data-testid="yes-upload-btn"
                onClick={this.props.uploadReports}
              >
                YES
              </button>
              <button
                className={styles.secondaryBtn}
                onClick={() => this.setState({ showstreamingInput: true })}
              >
                No, Re-verify Income
              </button>
            </div>
          </div>
        ) : (
          <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
            {get(this.props, "chordCashContent")}
            <FormikForm
              initialValues={{
                amount: 0,
              }}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              <Form>
                <div
                  className={`${addArtistStyles.formContainer} ${
                    get(this.props, "chordCashContent", "") &&
                    styles.containerCC
                  }`}
                >
                  <div className={`form-group mb-0`}>
                    <FormField
                      name={ESTIMATES_FIELD_NAMES.AMOUNT.NAME}
                      as="numeric"
                      defaultValue={0}
                      placeholder={ESTIMATES_FIELD_NAMES.AMOUNT.PLACEHOLDER}
                      label={this.getInputLabel()}
                      containerClass={addArtistStyles.mainInputContainer}
                      errorClass={addArtistStyles.errorClass}
                      prefix="$"
                      suffix="  USD"
                      autoComplete="off"
                      noFloating
                    />
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      className={styles.primaryBtn}
                      type="submit"
                      name="submit"
                    >
                      VERIFY INCOME
                    </button>
                  </div>
                </div>
              </Form>
            </FormikForm>
          </div>
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export class TooSmallWidget extends Component {
  componentDidMount() {
    segment.track.artistTooSmall({}, true);
  }
  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={styles.content}>
          <div className={styles.text}>
            <div className={styles.title}>
              Sorry, we can’t offer you an advance right now.
            </div>
          </div>
          <div className={styles.subtitle}>
            Our data indicates that the streaming history for{" "}
            <span className={styles.boldText}>{this.props.artistName}</span>{" "}
            does not currently meet the criteria to receive an advance. We are
            unable to provide advances for artists who have not generated at
            least US $100 in streaming income in each of the last 6 months.
          </div>
          <div className={styles.tooSmall}>
            If you have generated a minimum of US $100 per month during the last
            6 months please email{" "}
            <a href={`mailto:hello@${getPartnerName()}.com`}>
              hello@{getPartnerName()}.com
            </a>{" "}
            with:
            <ol>
              <li>Your name </li>
              <li>
                Your “artist name” as it appears on major streaming services
              </li>
              <li>
                Your detailed distribution reports from the last 6 months
                showing total streaming revenues and stream counts (.xls, .csv,
                .xlsx, .txt files only. We can’t read screenshots)
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export class TooBigWidget extends Component {
  componentDidMount() {
    segment.track.artistTooBig({}, true);
  }
  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <GraphSvg />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>Congratulations!</div>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.boldText}>{this.props.artistName}</span> is
            in the top 1% globally in their streaming performance. Advance
            structures for artists this large tend to be complex, so to get the
            most out of our investor network, we’ll need to discuss your goals
            to help you best customize your offer.
          </div>
          <div className={styles.footerText}>
            Look out for an email from a team member!
          </div>
        </div>
      </div>
    );
  }
}

export class RequestFailedWidget extends Component {
  render() {
    return (
      <div className={styles.widgetBox}>
        <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
          <div className={styles.icon}>
            <AlertIcon />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>Request Failed</div>
          </div>
          <div className={styles.subtitle}>
            We apologize for the inconvenience. We had trouble processing your
            request.
          </div>
          <div className={styles.subtitle}>
            Please wait a moment and try again.
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.primaryBtn} onClick={this.props.onClick}>
              Retry
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export const ChoosePath = (props) => {
  const validationSchema = Yup.object().shape({
    path: Yup.string().required().label("This"),
  });

  const handleSubmit = (values) => {
    switch (values.path) {
      case CHOOSE_PATH_FIELDS().OPTIONS[0].value:
        return invoke(props, "handleUploadReportPath");
      case CHOOSE_PATH_FIELDS().OPTIONS[1].value:
        return invoke(props, "handleGetEstimatePath");
      case CHOOSE_PATH_FIELDS().OPTIONS[2].value:
        return invoke(props, "handleRequestCallPath");
      default:
        return false;
    }
  };

  return (
    <div className={styles.widgetBox}>
      <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
        <div className={styles.text}>
          <div className={styles.title}>How would you like to apply?</div>
        </div>
        <Formik
          initialValues={{
            path: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ ...form }) => (
            <Form>
              <div className={`${styles.formContainer}`}>
                <div className={`form-group mb-0`}>
                  <FormField
                    as="radio"
                    name={CHOOSE_PATH_FIELDS().NAME}
                    label={""}
                    radioValueList={
                      props.showAllPath
                        ? CHOOSE_PATH_FIELDS(props.rightsType).OPTIONS
                        : CHOOSE_PATH_FIELDS(props.rightsType).OPTIONS.slice(
                            0,
                            CHOOSE_PATH_FIELDS(props.rightsType).OPTIONS
                              .length - 1,
                          )
                    }
                    containerClass={styles.radioContainer}
                    data-testid="choose-path-radio"
                    onClick={async (e) => {
                      await invoke(
                        form,
                        "setFieldValue",
                        CHOOSE_PATH_FIELDS().NAME,
                        get(e, "target.value"),
                      );
                      await Promise.resolve();
                      form.handleSubmit();
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const RequestCall = (props) => {
  return (
    <div className={styles.widgetBox}>
      <div className={`${styles.content} ${styles.incomeVerifiedDiv}`}>
        <div className={styles.icon}>
          <CheckMarkSpike />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>Thank You for Your Request!</div>
        </div>
        <div className={styles.subtitle}>
          A team member will email you within the next business day to schedule
          a call. In the meantime, have a couple options to help you get
          started.
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.primaryBtn}
            data-testid="choose-path-upload-btn"
            onClick={() => invoke(props, "handleUploadReportPath")}
          >
            Upload My Reports
          </button>
          <button
            className={styles.secondaryBtn}
            data-testid="choose-path-estimate-btn"
            onClick={() => invoke(props, "handleGetEstimatePath")}
          >
            View my Estimates
          </button>
        </div>
      </div>
    </div>
  );
};

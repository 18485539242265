import React, { useContext, useState } from "react";
import { get } from "lodash";
import {
  FACEBOOK_PLATFORM,
  FAILED_TO_FETCH,
  INSTAGRAM_PLATFORM,
  WIKIPEDIA_PLATFORM,
  YOUTUBE_PLATFORM,
  API_URL,
} from "./constants";
import request from "../../utils/request";
import { ErrorContext } from "../../component/ErrorPopup/ErrorPopup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export const GetErrorMessage = (props) => {
  const values = useContext(ErrorContext);
  const [err, setErr] = useState(props.err);
  const errMessage = "Internal Server Error";
  const [called, setCalled] = useState(false);

  React.useEffect(() => {
    async function checkJson() {
      const error =
        get(props.err, "response", "") && !called && !values.isOpen
          ? await props.err.response.json()
          : props.err;
      setErr(error);
    }
    checkJson();
  }, [called, props.err, values.isOpen]);

  if (get(err, "message") === errMessage && !called) {
    try {
      toast.dismiss();
      setCalled(true);
      values.setIsOpen();
    } catch (e) {
      console.log(e);
    }
    return <></>;
  }
  if (err.message && get(err, "message") !== errMessage) {
    toast.update(get(props.toastProps, "toastProps.toastId", ""), {
      className: "",
    });
  }
  if (get(err, "message") === "Failed to fetch") {
    return <>{FAILED_TO_FETCH}</>;
  }

  return <>{get(err, "message")}</>;
};

GetErrorMessage.contextTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

export const getErrorMessage = GetErrorMessage;

export const getSocialMediaPayload = (
  instagramUrl,
  facebookUrl,
  youtubeUrl,
  wikipediaUrl,
) => {
  const socialMedia = [];
  if (instagramUrl) {
    socialMedia.push({
      platform: INSTAGRAM_PLATFORM,
      url: instagramUrl,
    });
  }
  if (facebookUrl) {
    socialMedia.push({
      platform: FACEBOOK_PLATFORM,
      url: facebookUrl,
    });
  }
  if (youtubeUrl) {
    socialMedia.push({
      platform: YOUTUBE_PLATFORM,
      url: youtubeUrl,
    });
  }
  if (wikipediaUrl) {
    socialMedia.push({
      platform: WIKIPEDIA_PLATFORM,
      url: wikipediaUrl,
    });
  }
  return socialMedia;
};

export const forbiddenCheck = () => {
  const data = {
    method: "GET",
  };
  const requestURL = `${API_URL}`;
  request(requestURL, data).catch();
};

export const hubSpotTracking = () => {
  const _hsq = window._hsq || [];
  _hsq.push([
    "setPath",
    `${window.location.pathname}${window.location.search}${window.location.hash}`,
  ]);
  _hsq.push(["trackPageView"]);
};

export const isChordCashLite = () =>
  window.location.hostname.includes("funding");

export const YT_VIDEO_ID_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)|shorts?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;

export const YT_THUMBNAIL_URL =
  "https://img.youtube.com/vi/[VIDEO_ID]/sddefault.jpg";

export const YT_THUMBNAIL_SMALL =
  "https://img.youtube.com/vi/[VIDEO_ID]/mqdefault.jpg";
export const YT_EMBED_URL =
  "https://www.youtube-nocookie.com/embed/[VIDEO_ID]?rel=0&autoplay=1&modestbranding=1";

export const GetIframeVideo = (props) => (
  <iframe
    className={props.className}
    title={props.title}
    srcDoc={`<style>
      body, .full {
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      object-fit: cover;
      cursor: pointer;
      }
      </style>
      <div
      onClick={window.location.replace('${YT_EMBED_URL.replace(
        "[VIDEO_ID]",
        props.url.match(YT_VIDEO_ID_REGEX).pop(),
      )}')}
      class='full'
      >
      <img
      src='${(props.small ? YT_THUMBNAIL_SMALL : YT_THUMBNAIL_URL).replace(
        "[VIDEO_ID]",
        props.url.match(YT_VIDEO_ID_REGEX).pop(),
      )}'
      class='full'
      loading='lazy'
      />
      <svg
      version='1.1'
      viewBox='0 0 68 48'
      width='68px'
      style='position: relative;'
      >
      <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
      <path d='M 45,24 27,14 27,34' fill='#fff'></path>
      </svg>
      </div>`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  />
);

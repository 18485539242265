import React, { Component, createRef } from "react";
import ReactModal from "react-modal";
import _, { get } from "lodash";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import {
  IMG_EXTENSIONS_REGEX,
  IMG_MAX_SIZE,
  IMG_NOT_AVAILABLE_CASES,
  IMG_RATIO_LIMIT,
  maxCharsValidMessage,
  PDF_REGEX,
  INSTRUCTION_MAX_SIZE,
} from "../AdminDashboard/WhiteLabel/helper";
import whiteLabelStyles from "../AdminDashboard/WhiteLabel/WhiteLabel.module.scss";
import ImageUpload from "../AdminDashboard/WhiteLabel/ImageUpload";
import LeftPanel from "../AdminDashboard/LeftPanel";
import styles from "../AdminDashboard/AdminDashboard.module.scss";
import { GetErrorMessage } from "../helper";
import {
  API_URL,
  DISTRIBUTORS_EDIT,
  DISTRIBUTORS_CREATE,
  DISTRIBUTORS_API,
  DISTRIBUTORS,
  INFO_API,
} from "../constants";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import FormField from "../../../component/FormField/FormField";
import request from "../../../utils/request";
import {
  FIELD_NAMES,
  FIELD_INITIAL_VALUES,
  FIELD_VALIDATION_MESSAGES,
  DEFAULT_INSTRUCTION_LIST_HTML,
} from "./constants";
import {
  FIELD_MAX_CHARACTERS,
  FIELD_REGEX,
} from "../AdminDashboard/WhiteLabel/constants";
import { getPartnerName } from "../../../component/ThemeManager/helper";

const useDefaultToken = false;
class DistributorForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: FIELD_INITIAL_VALUES,
      //file
      logoUrl: { src: "", fileName: "" },
      logoUrlSizeError: false,
      logoUrlTypeError: false,
      logoUrlDimensionError: false,
      instructionsUrl: { src: "", fileName: "" },
      instructionsUrlSizeError: false,
      instructionsUrlTypeError: false,
      //edit
      isEdit: false,
      passwordBox: false,
    };
    this.editorRef = createRef(null);
  }

  componentDidMount () {
    this.validateForEdit();
    this.setTitle();
  }

  setTitle = () => {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    if (pathname === DISTRIBUTORS_EDIT) {
      document.title = `${getPartnerName()}: Edit Distributors`;
    } else {
      document.title = `${getPartnerName()}: Create Distributors`;
    }
  };

  setValuesFromAPI = (res) => {
    const { data } = res;
    return {
      initialValues: {
        [FIELD_NAMES.DISTRIBUTOR_NAME.NAME]: data.name,
        [FIELD_NAMES.INSTRUCTIONS_HTML.NAME]: data.instructionsHTML,
        [FIELD_NAMES.FEATURES_HTML.NAME]: get(data, 'featuresHTML', ""),
        [FIELD_NAMES.DISTRIBUTION_FEE.NAME]: get(data, 'distributionFee', ""),
        [FIELD_NAMES.TEMAPLATE_ID.NAME]: get(data, 'templateId', "") || '',
        [FIELD_NAMES.INSTRUCTIONS_URL.NAME]: data.instructionUrl,
        [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
      },
    };
  };

  setFormLogo = (res, type) => {
    const fileName =
      get(res, `data.${type}`) && res.data[type].split("/").pop().trim();
    this.setState({
      [type]: { src: res.data[type] || "", fileName },
    });
  };

  validateForEdit = () => {
    const {
      history: {
        location: { pathname, state },
      },
    } = this.props;
    if (
      pathname.includes(DISTRIBUTORS_EDIT) &&
      get(state, "distributorData._id")
    ) {
      this.setState({
        isEdit: true,
        loading: true,
      });
      const data = {
        method: "GET",
      };
      const requestUrl = `${API_URL}${DISTRIBUTORS_API}${INFO_API}?id=${state.distributorData._id}`;
      request(requestUrl, data, useDefaultToken)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setFormLogo(res, "logoUrl");
            this.setFormLogo(res, "instructionsUrl");
            const values = this.setValuesFromAPI(res);
            this.setState({ initialValues: values.initialValues });
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    } else {
      this.setState({ isEdit: false });
      this.props.history.push(DISTRIBUTORS_CREATE);
    }
  };

  validationSchema = Yup.object().shape({
    [FIELD_NAMES.DISTRIBUTOR_NAME.NAME]: Yup.string()
      .required()
      .label(FIELD_NAMES.DISTRIBUTOR_NAME.LABEL)
      .max(
        FIELD_MAX_CHARACTERS.DISPLAY_NAME,
        maxCharsValidMessage(
          FIELD_NAMES.DISTRIBUTOR_NAME.LABEL,
          FIELD_MAX_CHARACTERS.DISPLAY_NAME,
        ),
      )
      .matches(
        FIELD_REGEX.CTA_BUTTON_TEXT,
        FIELD_VALIDATION_MESSAGES[FIELD_NAMES.DISTRIBUTOR_NAME.NAME],
      ),
    [FIELD_NAMES.INSTRUCTIONS_HTML.NAME]: Yup.string()
      .required()
      .label(FIELD_NAMES.INSTRUCTIONS_HTML.LABEL)
      .max(6000),
    [FIELD_NAMES.FEATURES_HTML.NAME]: Yup.string()
      .label(FIELD_NAMES.FEATURES_HTML.LABEL)
      .max(6000),
    [FIELD_NAMES.DISTRIBUTION_FEE.NAME]: Yup.string()
      .label(FIELD_NAMES.DISTRIBUTION_FEE.LABEL)
      .max(100),
    [FIELD_NAMES.TEMAPLATE_ID.NAME]: Yup.string()
      .label(FIELD_NAMES.TEMAPLATE_ID.LABEL)
      .max(100),
    [FIELD_NAMES.ADMIN_PASSWORD.NAME]: Yup.string().label(
      FIELD_NAMES.ADMIN_PASSWORD.LABEL,
    ),
  });

  checkImageValidation = (file, type) => {
    this.setState({
      logoUrlTypeError: false,
      logoUrlDimensionError: false,
      logoUrlSizeError: false,
      instructionsUrlSizeError: false,
      instructionsUrlTypeError: false,
    });
    const fileType =
      type === "instructionsUrl" ? PDF_REGEX : IMG_EXTENSIONS_REGEX;
    if (!fileType.test(file[0].type)) {
      this.setState({ [`${type}TypeError`]: true });
      return false;
    }
    const maxSize =
      type === "instructionsUrl" ? INSTRUCTION_MAX_SIZE : IMG_MAX_SIZE;
    if (file[0].size > maxSize) {
      this.setState({ [`${type}SizeError`]: true });
      return false;
    }
    return file;
  };

  onChangeFileHandler = (event, type) => {
    const file = event.target.files;
    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file, type);
    }
    if (validateFile && type === "instructionsUrl") {
      const src = window.URL.createObjectURL(file[0]);
      this.setState({
        [type]: {
          fileName: file[0].name,
          src,
        },
      });
      event.target.value = null;
      return true;
    }
    if (validateFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file[0]);
      const fileToSave = file[0];
      img.onload = () => {
        const ratio = img.width / img.height;
        const minRatio = IMG_RATIO_LIMIT.MIN;
        const maxRatio = IMG_RATIO_LIMIT.MAX;
        if (!(ratio >= minRatio && ratio <= maxRatio)) {
          this.setState({ [`${type}DimensionError`]: true });
        } else {
          this.setState({
            [type]: {
              fileName: fileToSave && fileToSave.name,
              src: img.src,
            },
          });
          window.URL.revokeObjectURL(file[0]);
        }
      };
    }
    event.target.value = null;
  };

  checkIfImageChanged = (type) => {
    if (this.state[type].src.includes("blob")) {
      return true;
    }
    return false;
  };

  getImageBlob = async (type) => {
    const payload = {
      blob: true,
    };
    return await request(this.state[type].src, payload).then((r) => r);
  };

  onSubmit = async (values) => {
    const formData = new FormData();
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { isEdit, logoUrl, instructionsUrl } = this.state;

    // app-logo
    const hasLogoUrlChanged = this.checkIfImageChanged("logoUrl");
    if (hasLogoUrlChanged) {
      const logoUrlBlob = await this.getImageBlob("logoUrl");
      formData.append("distributorLogo", logoUrlBlob, logoUrl.fileName);
    }

    const hasInstructionsUrlChanged =
      this.checkIfImageChanged("instructionsUrl");
    if (hasInstructionsUrlChanged) {
      const instructionsUrlBlob = await this.getImageBlob("instructionsUrl");
      formData.append(
        "instructionsPdf",
        instructionsUrlBlob,
        instructionsUrl.fileName,
      );
    }

    formData.append(
      "distributorName",
      values[FIELD_NAMES.DISTRIBUTOR_NAME.NAME],
    );

    formData.append(
      "instructionsHTML",
      values[FIELD_NAMES.INSTRUCTIONS_HTML.NAME],
    );

    formData.append(
      "featuresHTML",
      values[FIELD_NAMES.FEATURES_HTML.NAME],
    );

    formData.append(
      "distributionFee",
      values[FIELD_NAMES.DISTRIBUTION_FEE.NAME],
    );

    formData.append(
      "templateId",
      values[FIELD_NAMES.TEMAPLATE_ID.NAME],
    );

    // password
    formData.append("adminPassword", values[FIELD_NAMES.ADMIN_PASSWORD.NAME]);
    if (isEdit) {
      formData.append("id", state.distributorData._id);
    }

    this.setState({
      loading: true,
    });

    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${DISTRIBUTORS_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(
            `${values[FIELD_NAMES.DISTRIBUTOR_NAME.NAME]
            } distributor is successfully ${isEdit ? `updated!` : `created!`}`,
          );
          this.props.history.push(DISTRIBUTORS);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  editorSetup = (editor) => {
    editor.ui.registry.addButton("customButton", {
      text: "Default Instruction List",
      onAction: (_) => {
        editor.insertContent(DEFAULT_INSTRUCTION_LIST_HTML);
      },
    });
  };

  editorInit = {
    placeholder: FIELD_NAMES.INSTRUCTIONS_HTML.PLACEHOLDER,
    height: 300,
    width: "100%",
    menubar: "edit view insert format help",
    branding: false,
    extended_valid_elements: "style",
    custom_elements: "style",
    plugins: ["advlist lists link preview", "code", "media table paste code "],
    toolbar:
      "customButton | undo redo | formatselect | " +
      "bold italic forecolor backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat",
    toolbar_sticky: true,
    toolbar_mode: "sliding",
    setup: this.editorSetup,
    content_style: "body { font-family:Montserrat; font-size:14px }",
  };

  getHtmlEditor = (setFieldValue) => (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onInit={(evt, editor) => (this.editorRef.current = editor)}
      initialValue={
        this.state.initialValues[FIELD_NAMES.INSTRUCTIONS_HTML.NAME]
      }
      onEditorChange={(e) => {
        setFieldValue(FIELD_NAMES.INSTRUCTIONS_HTML.NAME, e);
      }}
      init={this.editorInit}
    />
  );

  getFeatureHtmlEditor = (setFieldValue) => (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onInit={(evt, editor) => (this.editorRef.current = editor)}
      initialValue={
        this.state.initialValues[FIELD_NAMES.FEATURES_HTML.NAME]
      }
      onEditorChange={(e) => {
        setFieldValue(FIELD_NAMES.FEATURES_HTML.NAME, e);
      }}
      init={this.editorInit}
    />
  );

  renderNameAndLogo = () => {
    const {
      logoUrl,
      logoUrlSizeError,
      logoUrlTypeError,
      logoUrlDimensionError,
      isEdit,
    } = this.state;
    return (
      <div className={whiteLabelStyles.nameAndLogoContainer}>
        <p>Distributor Name and Logo</p>
        <div className={whiteLabelStyles.subContainer}>
          <div>
            <div className={`form-group ${whiteLabelStyles.formGroup}`}>
              <FormField
                name={FIELD_NAMES.DISTRIBUTOR_NAME.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.DISTRIBUTOR_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.DISTRIBUTOR_NAME.LABEL}*`}
                disabled={isEdit}
              />
            </div>
          </div>
          <div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="Distributor Logo"
                aspectRatio="3:1"
                maxSize="250 KB"
                editLogoMessage="Edit Distributor Logo"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "logoUrl")}
                imageAvailable={logoUrl.src}
                buttonText="+ Add Distributor Logo"
                sizeError={logoUrlSizeError}
                typeError={logoUrlTypeError}
                dimensionError={logoUrlDimensionError}
                data-testid="LogoUrl"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  validateCondition = (validateForm, handleSubmit) => {
    validateForm().then((val) => {
      !_.size(val) ? this.handleOpenPasswordBox() : handleSubmit();
    });
  };

  checkLogoError = () => {
    const { logoUrlDimensionError, logoUrlTypeError, logoUrlSizeError } =
      this.state;
    return logoUrlDimensionError || logoUrlSizeError || logoUrlTypeError;
  };

  renderSubmitButton = (validateForm, handleSubmit) => {
    const {
      loading,
      isEdit,
      instructionsUrl,
      instructionsUrlSizeError,
      instructionsUrlTypeError,
    } = this.state;
    const appError =
      this.checkLogoError() ||
      instructionsUrlSizeError ||
      instructionsUrlTypeError;
    const areLogosUploaded = IMG_NOT_AVAILABLE_CASES.some(
      (imgCase) => instructionsUrl.src === imgCase,
    );
    const imageError = appError || areLogosUploaded;
    const isButtonDisabled = loading || imageError;
    return (
      <div className={whiteLabelStyles.saveButton}>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-link"
          onClick={() => {
            toast.info("Data entered in this form will not be saved.");
            this.props.history.push(DISTRIBUTORS);
          }}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            this.validateCondition(validateForm, handleSubmit);
          }}
          type="button"
          className={isButtonDisabled && whiteLabelStyles.disabledSubmitButton}
          disabled={isButtonDisabled}
          data-testid="isEditbtn"
        >
          {isEdit ? "Save Changes" : "Create Distributor"}
        </button>
      </div>
    );
  };

  handleOpenPasswordBox = () => this.setState({ passwordBox: true });
  handleClosePasswordBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ passwordBox: false });
  };

  renderAdminPasswordField = (isValid, handleSubmit, values, setFieldValue) => {
    if (!isValid && this.state.passwordBox)
      this.setState({ passwordBox: false });
    return (
      <ReactModal
        isOpen={this.state.passwordBox && isValid}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={() => this.handleClosePasswordBox(setFieldValue)}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          {this.state.isEdit ? "Save Changes" : "Create Distributor"} for{" "}
          <span className={styles.primaryName}>
            {values[FIELD_NAMES.DISTRIBUTOR_NAME.NAME]}
          </span>
          ?
        </h3>
        <div className={styles.changeSource}>
          <p>Please input the password to confirm these changes: </p>
          <div
            className={`${styles.changeTo} form-group justify-content-center`}
          >
            <FormField
              name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
              label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
              placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
              as="password"
            />
          </div>
        </div>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
            onClick={handleSubmit}
            type="submit"
          >
            Okay
          </button>
          <button
            className={styles.secondary}
            onClick={() => this.handleClosePasswordBox(setFieldValue)}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    );
  };

  instructionDetailsContainer = (setFieldValue, values) => (
    <div className={whiteLabelStyles.wpDomainURL}>
      <p>{FIELD_NAMES.INSTRUCTIONS_HTML.LABEL_TO_DISPLAY}</p>
      <div className={whiteLabelStyles.subContainer}>
        <span className="textPrimary textSize14 textBold">
          {FIELD_NAMES.INSTRUCTIONS_HTML.LABEL}*
        </span>
        <div className={`form-group ${whiteLabelStyles.formGroup} d-block`}>
          {this.getHtmlEditor(setFieldValue)}
          <FormField
            name={FIELD_NAMES.INSTRUCTIONS_HTML.NAME}
            type="text"
            as={"textMsg"}
          />
        </div>
        <p className={whiteLabelStyles.whiteListedURLHeader}>
          {FIELD_NAMES.INSTRUCTIONS_URL.EXTRA_TEXT}
        </p>
        <div className={`form-group ${whiteLabelStyles.formGroup}`}>
          {get(this.state, "instructionsUrl.src", "") && (
            <a
              href={this.state.instructionsUrl.src}
              target="_blank"
              rel="noopener noreferrer"
              className={whiteLabelStyles.pdfLink}
            >
              {this.state.instructionsUrl.fileName}
            </a>
          )}
          <div className={whiteLabelStyles.customInstructionUpload}>
            <ImageUpload
              accept=".pdf"
              uploadType="Instruction PDF"
              maxSize="5 MB"
              editLogoMessage="Edit Pdf"
              multiple={false}
              onChange={(e) => this.onChangeFileHandler(e, "instructionsUrl")}
              imageAvailable={this.state.instructionsUrl.src}
              buttonText={FIELD_NAMES.INSTRUCTIONS_URL.LABEL}
              sizeError={this.state.instructionsUrlSizeError}
              typeError={this.state.instructionsUrlTypeError}
              data-testid="instructionsUrl"
            />
          </div>
        </div>
      </div>
    </div>
  );

  featuresDetailsContainer = (setFieldValue, values) => (
    <div className={whiteLabelStyles.wpDomainURL}>
      <p>{FIELD_NAMES.FEATURES_HTML.LABEL_TO_DISPLAY}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div className={`form-group ${whiteLabelStyles.formGroup} d-block`}>
          {this.getFeatureHtmlEditor(setFieldValue)}
          <FormField
            name={FIELD_NAMES.FEATURES_HTML.NAME}
            type="text"
            as={"textMsg"}
          />
        </div>
        <div className={`form-group ${whiteLabelStyles.formGroup} d-block`}>
          <FormField
            name={FIELD_NAMES.DISTRIBUTION_FEE.NAME}
            type="text"
            as={"input"}
            placeholder={FIELD_NAMES.DISTRIBUTION_FEE.PLACEHOLDER}
            label={`${FIELD_NAMES.DISTRIBUTION_FEE.LABEL}`}
          />
        </div>
        <div className={`form-group ${whiteLabelStyles.formGroup} d-block`}>
          <FormField
            name={FIELD_NAMES.TEMAPLATE_ID.NAME}
            type="text"
            as={"input"}
            placeholder={FIELD_NAMES.TEMAPLATE_ID.PLACEHOLDER}
            label={`${FIELD_NAMES.TEMAPLATE_ID.LABEL}`}
          />
        </div>
      </div>
    </div>
  );

  render () {
    const { loading, isEdit } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>{isEdit ? "Edit" : "Create New"} Distributor</h1>
            </div>
            <div className={whiteLabelStyles.contentContainer}>
              <Formik
                initialValues={this.state.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
                data-testid="formRender"
              >
                {({
                  values,
                  isValid,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                }) => (
                  <Form>
                    {this.renderNameAndLogo()}
                    {this.instructionDetailsContainer(setFieldValue, values)}
                    {this.featuresDetailsContainer(setFieldValue, values)}
                    {this.renderSubmitButton(validateForm, handleSubmit)}
                    {this.renderAdminPasswordField(
                      isValid,
                      handleSubmit,
                      values,
                      setFieldValue,
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {loading && <Loader data-testid="Loader" />}
      </>
    );
  }
}
export default DistributorForm;

import React, { Component } from "react";
import { get, invoke, set } from "lodash";
import {
  setTitle,
  getPartnerName,
} from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import styles from "./AcceptOffer.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import Loader from "../../../component/Loader";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  OFFER_CONTRACT_PDF,
  OFFER_SUMMARY,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY_API,
  ONBOARDING_DETAILS,
  REJECT_OFFER,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import ContactPopup from "../../../component/ContactPopup";
import {
  ACCEPT_OFFER_TITLE,
  UNITED_STATES_COUNTRY,
  OFFER_ACTION_TEXT,
  ASSETS,
  POST_OFFER_ACTION_TEXT,
  ENTITY_OPTIONS,
  ONBOARDING_NOTE,
  BOOLEAN_RADIO,
} from "./constants";
import dataURItoBlob from "../MyAccount/dataURItoBlob";
import classes from "../YourAdvance/YourAdvance.module.scss";
import moment from "moment";
import { ReactComponent as WarningIcon } from "../../../assets/logos/warning.svg";
import { ReactComponent as TextMessage } from "../../../assets/logos/np_text-message.svg";
import segment from "../../../utils/segment";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { RejectOfferSurveyModal } from "../YourAdvance/helper";
import ReactModal from "react-modal";
import OnboardForm from "./OnboardingForm";

class AcceptOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitLoader: false,
      offerStage: {},
      offer: {},
      pdfUrl: "",
      pdfLoading: false,
      isContactOpen: false,
      isLocationUS: false,
      isAccepted: false,
      navigationStatus: {},
      onboardData: {},
      onboardingModal: false,
      onboardEntityType: "",
      agentCode: null,
      totalPDFPage: 1,
      isRejectOfferModalOpen: false,
    };
    this.navigationCallback = null;
  }

  componentDidUpdate() {
    setTitle(ACCEPT_OFFER_TITLE, this.context);
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false&isLocationNeeded=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState(
            {
              offerStage: res.data.offerStage,
              offer: get(res.data, "offer", {}),
              isLocationUS:
                get(res, "data.userCountry", "").toLowerCase() ===
                UNITED_STATES_COUNTRY,
              artistProfile: {
                url: get(
                  res.data,
                  `profilePicture[${
                    get(res.data, "profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                ),
                name: res.data.name,
              },
            },
            this.getOfferPdfData,
          );

          this.checkSteps(
            res.data,
            get(res.data, "offerStage.verificationStatus"),
            get(res.data, "offer_approved", undefined),
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, verificationStatus, isOfferApproved) => {
    if (!isOfferApproved || get(data, "offerStage.defaultOffer.isAccepted")) {
      this.setState({ isAccepted: true });
      return false;
    }
    if (!verificationStatus.offerSummary) {
      this.props.history.push({
        pathname: OFFER_SUMMARY,
        state: { reviewData: data },
      });
    }
    return true;
  };

  getOfferPdfData = () => {
    this.setState({ pdfLoading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_CONTRACT_PDF}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ pdfLoading: false });
        if (res.status) {
          let blob = {};
          let pdfUrl = "";
          if (
            res.data.approved_offer_pdf &&
            get(res.data, "approved_offer_pdf", "").includes("base64")
          ) {
            blob = dataURItoBlob(res.data.approved_offer_pdf);
            pdfUrl = URL.createObjectURL(blob);
          }
          this.setState({
            pdfUrl,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  downloadOffer = () => {
    const a = document.createElement("a");
    a.href = this.state.pdfUrl;
    a.download = this.state.pdfUrl.split("/").pop() || "offer.pdf";
    a.style.display = "none";
    a.target = "_blank";
    document.body.append(a);
    a.click();
    document.body.removeChild(a);
  };

  pdfLoading = () => (
    <div className={`d-flex justify-content-center`}>
      <div className={`spinner-border spinner-border-sm`} />
    </div>
  );

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ totalPDFPage: numPages });
  };

  onPDFLoadError = (err) => {
    console.error("PDF Loading Error ", err);
  };

  renderPDFLoad = () => {
    return (
      <div className={styles.pdfLoading}>
        <i className={`spinner-border`}></i>
      </div>
    );
  };

  handleContactToggle = () => {
    this.setState({ isContactOpen: !this.state.isContactOpen });
  };

  handleRejectOfferModalClose = () => {
    this.setState({ isRejectOfferModalOpen: false });
  };

  setNavigationStatus = (status, callback) => {
    this.setState({ navigationStatus: status, email: get(status, "email") });
    this.navigationCallback = callback;
    this.getOnboardingDetails();
    !status.adjustTerms && this.props.history.push(OFFER_SUMMARY);
  };

  updateUserData = (selectedArtist) => {
    this.setState({
      agentCode: selectedArtist.agent_code,
      phone: get(selectedArtist, "phone"),
    });
  };

  handleURLLocation = () => {
    window.location.href = this.state.isLocationUS
      ? get(this.context, "getContractUrl.nonInternationalUrl", "")
      : get(this.context, "getContractUrl.internationalUrl", "");
  };

  isFormIncomplete = () =>
    (get(this.state, "onboardData.artistName") ||
      get(this.state, "onboardData.representativeEmail")) &&
    !get(this.state, "navigationStatus.isAccepted");

  toggleOnboardingModal = () => {
    if (this.state.onboardingModal) {
      this.getOnboardingDetails();
      invoke(this, "navigationCallback");
    }
    this.setState({ onboardingModal: !this.state.onboardingModal });
  };

  proceedToOnboarding = () => {
    segment.track.proceededToOnboarding();
    if (!this.isFormIncomplete()) {
      this.toggleOnboardingModal();
      return false;
    }
    this.setState({ submitLoader: true });
    segment.track.proceededToOnboarding();
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ submitLoader: false });
        if (get(res, "status")) {
          const onboardData = get(res, "data", {});
          this.setOnboardData(res, onboardData);
          this.setState({ onboardData }, this.toggleOnboardingModal);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleMondayFormRedirection = () => {
    this.setState({ submitLoader: true });
    segment.track.proceededToOnboarding();
    const payload = { isMondayFormSubmitted: true };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_SUMMARY_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (get(res, "status")) {
          this.handleURLLocation();
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setOnboardData = (res, onboardData) => {
    if (
      !get(onboardData, "artistEmail") &&
      !get(onboardData, "representativeEmail")
    ) {
      set(onboardData, "artistEmail", get(this.state, "email", ""));
    }
    if (!get(onboardData, "phone")) {
      set(
        onboardData,
        "phone",
        `${get(this.state, "phone.countryCode")}${get(
          this.state,
          "phone.value",
        )}`,
      );
      set(onboardData, "countryCode", get(this.state, "phone.countryCode"));
    } else {
      set(
        onboardData,
        "phone",
        `${get(res, "data.countryCode")}${get(res, "data.phone")}`,
      );
      set(onboardData, "countryCode", get(res, "data.countryCode"));
    }
    if (get(onboardData, "artistName")) {
      set(
        onboardData,
        "isCurrentAdvanceWithOtherPartner",
        !get(onboardData, "isCurrentAdvanceWithOtherPartner")
          ? BOOLEAN_RADIO[1].value
          : BOOLEAN_RADIO[0].value,
      );
    }
  };

  getOnboardingDetails = () => {
    this.setState({ submitLoader: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ submitLoader: false });
        if (res && res.status) {
          const onboardData = get(res, "data", {});
          this.setOnboardData(res, onboardData);
          this.setState({ onboardData });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  toggleRejectOffer = () => {
    this.setState({ loading: true });
    const data = {
      method: "PUT",
      body: {
        rejectOffer: true,
      },
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${REJECT_OFFER}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (get(res, "status")) {
          this.setState({ isRejectOfferModalOpen: true });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  onboardFormModal = () => {
    return (
      <ReactModal
        isOpen={this.state.onboardingModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.toggleOnboardingModal}
        className={styles.countryModal}
        overlayClassName={`${styles.modalOverlay} ${styles.mobileOverLay}`}
      >
        <OnboardForm
          onboardData={this.state.onboardData}
          handleClose={this.toggleOnboardingModal}
        />
      </ReactModal>
    );
  };

  actionButtonContainer = () => {
    if (
      get(this.state, "navigationStatus.isMondayFlow") ||
      (!(
        get(this.state, "onboardData.artistName") ||
        get(this.state, "onboardData.representativeEmail")
      ) &&
        !get(this.state, "navigationStatus.isAccepted"))
    ) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer}`}
        >
          <p className={styles.buttonNote}>{ONBOARDING_NOTE}</p>
          <button
            className={`${styles.primary}`}
            onClick={
              !get(this.state.navigationStatus, "isMondayFlow")
                ? this.proceedToOnboarding
                : this.handleMondayFormRedirection
            }
          >
            {!get(this.state.navigationStatus, "isMondayFlow")
              ? "Begin Onboarding"
              : "Proceed to Form"}
          </button>
          {!get(this.state, "navigationStatus.isAccepted") && (
            <button
              className={`${styles.secondary}`}
              data-testid="goBackBtn"
              onClick={() => {
                segment.track.backToSelectConfirmedOffer();
                this.props.history.push(OFFER_SUMMARY);
              }}
            >
              Adjust Deal Terms
            </button>
          )}
        </div>
      );
    }
    if (this.isFormIncomplete()) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer}`}
        >
          <span className={styles.pill}>
            <WarningIcon /> <span>Onboarding Form is Incomplete</span>
          </span>
          <p className={styles.buttonNote}>{ONBOARDING_NOTE}</p>
          <button
            className={`${styles.primary}`}
            onClick={this.proceedToOnboarding}
          >
            Resume Onboarding
          </button>
          <button
            className={`${styles.secondary}`}
            data-testid="goBackBtn"
            onClick={() => {
              segment.track.backToSelectConfirmedOffer();
              this.props.history.push(OFFER_SUMMARY);
            }}
          >
            Adjust Deal Terms
          </button>
          <button
            className={styles.linkBtn}
            data-testid="reject-offer-btn"
            onClick={this.toggleRejectOffer}
          >
            Reject Offers
          </button>
        </div>
      );
    }
    return (
      <div
        className={`${styles.pdfContractContainer} ${styles.getContractContainer}`}
      >
        <p className={styles.bold}>Don’t see an email?</p>
        <button
          className={`${styles.secondary}`}
          data-testid="contactBtn"
          onClick={this.handleContactToggle}
        >
          <TextMessage />
          <span>Contact Us</span>
        </button>
      </div>
    );
  };

  successOnboardMsg = () => {
    if (get(this.state, "onboardData.entityType") === ENTITY_OPTIONS[0].value) {
      return (
        <>
          <p className="text-center">
            We have sent an email to{" "}
            <span>{get(this.state, "onboardData.artistEmail")}</span> from
            no-reply@chordcash.com.
          </p>
          <p className="text-center">
            Click on the link in that email to enter our chordCash portal to
            securely complete your onboarding by providing banking details and
            completing ID verification.
          </p>
        </>
      );
    }
    return (
      <>
        <p className="text-center">
          We have sent <span>{get(this.state, "onboardData.artistName")}</span>{" "}
          an email at <span>{get(this.state, "onboardData.artistEmail")}</span>.
          The email is from no-reply@chordcash.com.
        </p>
        <p className="text-center">
          They will need to click on the link in the email to enter our
          chordCash portal to securely complete their onboarding by providing
          banking details and completing ID verification.
        </p>
      </>
    );
  };

  renderRightNav = () => (
    <div className={styles.actionContainer}>
      <div className={styles.btnOnboarding}>
        <div className={styles.actionsHeaderContainer}>
          {get(this.state.navigationStatus, "isMondayFlow") ||
          !get(this.state.navigationStatus, "isAccepted") ? (
            <>
              <p>
                Here’s a summary of your main deal points. Please review it
                carefully - you can download it or email it to yourself.
              </p>
              <p>
                When you are ready, click proceed and we’ll ask you for the
                information we need to generate a contract.
              </p>
            </>
          ) : (
            this.successOnboardMsg()
          )}
        </div>
        {this.actionButtonContainer()}
      </div>
    </div>
  );

  warningDiv = () => (
    <div className={styles.mainWarningDiv}>
      <span>
        {this.state.agentCode
          ? `beatBread is a direct to artists platform. Estimates and offers
        presented outside of our app by unauthorized representatives will not be
        honored.`
          : `beatBread is a direct to artists platform. Estimates and offers
          presented outside of our app by unauthorized representatives may not be
          honored and be invalidated by beatBread.`}
      </span>
    </div>
  );
  render() {
    const headerTitle = "Review Deal Summary";
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu
          offerStage={this.state.offerStage}
          className={styles.menuContainer}
          {...this.props}
          getStatus={this.setNavigationStatus}
          updateUserData={this.updateUserData}
        />
        <div className={classes.funnelContainer}>
          <LoginHeader headerTitle={headerTitle} />
          <div className={styles.pageContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div
                  className={`${styles.container} ${
                    !get(this.state, "navigationStatus.isMondayFlow") &&
                    (get(this.state, "onboardData.representativeEmail") ||
                      get(this.state, "onboardData.artistName"))
                      ? styles.onboardFlow
                      : ""
                  }`}
                >
                  <div className={styles.offerLetterContainer}>
                    <div className={styles.title}>
                      <span className={styles.expireDate}>
                        Valid through{" "}
                        {moment(get(this.state.offer, "expire")).format(
                          "MMM DD, YYYY",
                        )}
                      </span>
                      <span className={styles.actionText}>
                        {this.state.navigationStatus.isOnBoardFormSubmit
                          ? POST_OFFER_ACTION_TEXT
                          : OFFER_ACTION_TEXT}
                      </span>
                    </div>
                    <div
                      className={`${styles.detailsContainer} ${
                        this.state.loading ? "d-none" : ""
                      }`}
                    >
                      {this.state.pdfLoading && (
                        <div className={styles.pdfContainer}>
                          {this.pdfLoading()}
                        </div>
                      )}
                      {this.state.pdfUrl && (
                        <div className={styles.pdfContainer}>
                          <Document
                            file={this.state.pdfUrl}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={this.onPDFLoadError}
                            loading={this.renderPDFLoad}
                          >
                            {Array.from(
                              new Array(this.state.totalPDFPage),
                              (el, index) => (
                                <Page
                                  width={get(document, "body.clientWidth", 900)}
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                />
                              ),
                            )}
                          </Document>
                        </div>
                      )}
                      <div className={styles.offerDownload}>
                        <div
                          className={styles.btnLink}
                          onClick={this.downloadOffer}
                          disabled={this.state.loading || !this.state.pdfUrl}
                        >
                          <ASSETS.DownloadLogo />
                        </div>
                        <span
                          onClick={this.downloadOffer}
                          className={`${styles.secondaryOffer}`}
                          disabled={this.state.loading || !this.state.pdfUrl}
                          data-testid="downloadPdf"
                        >
                          Download Offer Letter
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    {getPartnerName() === "beatBread" && this.warningDiv()}
                    {this.renderRightNav()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.submitLoader) && (
            <Loader light backgroundNone />
          )}
          {this.onboardFormModal()}
          <ContactPopup
            isOpen={this.state.isContactOpen}
            onRequestClose={this.handleContactToggle}
          />
          <RejectOfferSurveyModal
            isOpen={this.state.isRejectOfferModalOpen}
            onRequestClose={this.handleRejectOfferModalClose}
            name={get(this.state, "artistProfile.name")}
            email={get(this.state, "email")}
          />
        </div>
      </div>
    );
  }
}

AcceptOffer.contextType = ThemeContext;
export default AcceptOffer;
